<template>
    <el-cascader
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :size="size"
      :options="options"
      :props="{ checkStrictly: changeonselect }"
      clearable
      @change="handleChange">
    </el-cascader>
</template>

<script>
  export default {
    name: "scascader",
    props:["defaultValue","placeholder","codeType","codeLevel","codeValue","size","disabled","changeonselect"],
    data() {
      return {
        value: [],
        options: []
      };
    },
    watch: {
      defaultValue: {
        handler: function (val) {
          this.value = val;
        }
      }
    },
    methods: {
      handleChange(value) {
        // console.log(value);
        this.$emit("setMValue",value)
      },
      async initDictData() {
        //根据字典Code, 初始化字典数组
        let params = {codeType:this.codeType,codeLevel:this.codeLevel,codeValue:this.codeValue};
        // let params = {codeType:"JJ_AREA",codeLevel:"2",codeValue:""};

        await this.$axios.$post("/codeValue/queryCascaderOption",params).then(response=>{
          this.options  = response.result;
        });
      },
    },
    created(){


      this.initDictData();
      if (this.defaultValue) {
        for(let i=0;i<this.defaultValue.length;i++){
          if(this.defaultValue[i]){
            this.value.push(this.defaultValue[i])
          }
        }
      }
      console.log(this.defaultValue)
      // console.log(this.value)
    }
  };
</script>


<style lang="less" >

</style>