<template>
    <el-cascader
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :size="size"
      :options="options"
      :props="{ checkStrictly: changeonselect }"
      clearable
      @change="handleChange">
    </el-cascader>
</template>

<script>
  export default {
    name: "zhucescascader",
    props:["defaultValue","placeholder","codeType","codeLevel","codeValue","size","disabled","changeonselect"],
    data() {
      return {
        value: [],
        options: [
          {table:'',value:'',children:[ {table:'',value:'',children:[]}]}
        ]
      };
    },
    watch: {
      defaultValue: {
        handler: function (val) {
          this.value = val;
        }
      }
    },
    methods: {

      handleChange(value) {
        // console.log(value);
        this.$emit("setMValue",value)
      },
      async initDictData() {
        //根据字典Code, 初始化字典数组
        let params = {codeType:this.codeType,codeLevel:this.codeLevel,codeValue:this.codeValue};
        // let params = {codeType:"JJ_AREA",codeLevel:"2",codeValue:""};

          this.options[0].label='广东省';
          this.options[0].value='p17';
          this.options[0].children[0].label='深圳市';
          this.options[0].children[0].value='c214';
        await  this.$axios.$post("/api/codeValue/getCodeValue", {
            codeType: 'JJ_AREA',
            subCodeValue: 'c214',
          }).then(response => {
          for (var i = 0; i <= response.length-1 ; i++) {
            const li = {};
            li['label'] = response[i].codeName;
            li['value'] = response[i].codeValue;
            this.options[0].children[0].children.push(li);
          }
          });
        // await this.$axios.$post("/codeValue/queryCascaderOption",params).then(response=>{
        //   this.options  = response.result;
        // });
      },
    },
    created(){
      this.initDictData();
      this.value = this.defaultValue;
      console.log(this.defaultValue)
      // console.log(this.value)
    }
  };
</script>
